import { z } from 'zod';
import { CommentSchema } from './comment';
import { EmployeeSchema } from './employee';
import { TimeWithRelationsSchema } from './time';

export const TaskStateSchema = z.enum([
	'complete',
	'inProgress',
	'new',
	// tyto stavy nepoužíváme
	// 'late',
	// 'archived',
]);
export type TaskState = z.infer<typeof TaskStateSchema>;

export const TaskSchema = z.object({
	state: TaskStateSchema.nullable(),
	id: z.string().cuid(),
	name: z.string().nullable(),
	description: z.string().nullable(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	jobId: z.string().nullable(),
	employeeId: z.string().nullable(),
	typeOfTask: z.string().nullable(),
	estimatedTime: z.number().nullable(),
	estimatedPrice: z.number().nullable(),
	priority: z.number().nullable(),
	deletedAt: z.coerce.date().nullable(),
});

export const TaskWithRelationsSchema = TaskSchema.extend({
	employee: EmployeeSchema.nullish(),
	times: z.array(TimeWithRelationsSchema).nullish(),
	comments: z.lazy(() => z.array(CommentSchema)),
	job: z
		.object({
			id: z.string().cuid(),
			title: z.string().nullable(),
		})
		.nullish(),
});

export const TaskWithTimesSchema = TaskSchema.extend({
	times: z.array(TimeWithRelationsSchema).nullish(),
});

export type Task = z.infer<typeof TaskSchema>;
export type TaskWithRelations = z.infer<typeof TaskWithRelationsSchema>;
export type TaskWithTimes = z.infer<typeof TaskWithTimesSchema>;
