import { z } from 'zod';
import { verifyRC } from '../validations/personalNumber';
import isValidBankAccount from '../validations/bankAccount';
import { TimeSchema } from './time';
import { WorkScheduleSchema } from './workSchedule';
import { AddressSchema } from './address';

export const EmployeeSchema = z.object({
	id: z.string().cuid(),
	image: z.string().nullable(),
	firstName: z.string(),
	surName: z.string(),
	email: z.string().email(),
	birthDate: z.coerce
		.date()
		.refine((birthDate) => (birthDate ? birthDate < new Date() : true), {
			params: { i18n: 'dateMustBeInPast' },
		})
		.refine(
			(birthDate) =>
				birthDate ? birthDate <= new Date(new Date().setFullYear(new Date().getFullYear() - 15)) : true,
			{
				params: { i18n: 'dateMustBeOlderThan15Years' },
			},
		)
		.nullable(),
	phoneNumber: z.string().nullable(),
	jobTitle: z.string().nullable(),
	personalNumber: z
		.string()
		.min(9)
		.max(11)
		.regex(/^\s*(\d\d)(\d\d)(\d\d)[ /]*(\d\d\d)(\d?)\s*$/)
		.refine((value) => !value || verifyRC(value))
		.nullable(),
	accountNumber: z
		.string()
		.min(7)
		.max(20)
		.regex(/^(?:([0-9]{1,6})-)?([0-9]{2,10})\/([0-9]{4})$/)
		.refine((value) => isValidBankAccount(value))
		.nullable(),
	identificationNumber: z.string().nullable(),
	placeOfBirth: z.string().nullable(),
	insuranceNumber: z.number().nullable(),
	startDate: z.coerce.date().nullable(),
	ownerId: z.string().nullable(),
	endDate: z.coerce.date().nullable(),
	endContractDate: z.coerce.date().nullable(),
	hourlyRate: z.number().nullable(),
	typeOfContract: z.string().nullable(),
	times: z.array(TimeSchema).nullish(),
	workSchedule: z.array(WorkScheduleSchema).nullish(),
	invitations: z
		.array(
			z
				.object({
					role: z.enum(['user', 'admin', 'superAdmin']).nullable(),
				})
				.nullable(),
		)
		.nullish(),
	userRoles: z
		.array(
			z.object({
				role: z.enum(['user', 'admin', 'superAdmin']).nullable(),
				active: z.boolean(),
				user: z
					.object({
						image: z.string().nullable(),
					})
					.nullable(),
			}),
		)
		.nullish(),
	deletedAt: z.coerce.date().nullable(),
});

export type Employee = z.infer<typeof EmployeeSchema>;

export const EmployeeWithRelationsSchema = EmployeeSchema.extend({
	addresses: z.array(AddressSchema).nullish(),
});

export type EmployeeWithRelations = z.infer<typeof EmployeeWithRelationsSchema>;
