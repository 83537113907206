import { z } from 'zod';
import { RoleSchema, UserRoleSchema } from '../model/userRole';
import { UiSettingsSchema } from '../model/uiSettings';

export const CreateUserRoleSchema = UserRoleSchema.omit({
	id: true,
	employee: true,
	deletedAt: true,
	uiSettings: true,
}).extend({
	role: z.enum(['user', 'admin', 'superAdmin']),
	email: z.string(),
});

export type CreateUserRole = z.infer<typeof CreateUserRoleSchema>;

export const UpdateUserRoleSchema = UserRoleSchema.omit({
	id: true,
	employee: true,
	deletedAt: true,
}).extend({
	ownerId: z.string().optional(),
	employeeId: z.string().optional(),
	active: z.boolean().optional(),
	userId: z.string().optional(),
	role: z.enum(['user', 'admin', 'superAdmin']).optional(),
	uiSettings: UiSettingsSchema.optional(),
});

export type UpdateUserRole = z.infer<typeof UpdateUserRoleSchema>;

export const FindUserRoleQuerySchema = z.object({
	where: z
		.object({
			id: z.string().cuid().optional(),
			userId: z.string().optional(),
			ownerId: z.string().cuid().optional(),
			employeeId: z.string().cuid().nullish().optional(),
			active: z.boolean().optional(),
			OR: z
				.array(
					z.object({
						role: RoleSchema.nullable(),
					}),
					z.object({
						role: RoleSchema.nullable(),
					}),
				)
				.optional(),
		})
		.optional(),
	include: z
		.object({
			employee: z.boolean().optional(),
			user: z.boolean().optional(),
		})
		.optional(),
});

export type FindUserRoleQuery = z.infer<typeof FindUserRoleQuerySchema>;

export const GetUserRolesByOwnerIdParamsQuery = z.object({
	q: FindUserRoleQuerySchema.optional(),
});

export type GetUserRolesByOwnerIdParams = z.infer<typeof GetUserRolesByOwnerIdParamsQuery>;

export const FindUserRoleSettingsQuerySchema = z.object({
	where: z
		.object({
			id: z.string().cuid().optional(),
			userId: z.string().optional(),
			ownerId: z.string().cuid().optional(),
		})
		.optional(),
});

export const GetUserRoleSettingsByOwnerIdParamsQuery = z.object({
	ownerId: z.string().cuid().optional(),
	userId: z.string().optional(),
});

export type GetUserRoleSettingsByOwnerIdParams = z.infer<typeof GetUserRoleSettingsByOwnerIdParamsQuery>;
