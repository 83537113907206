import { z } from 'zod';

export const NotificationTypeSchema = z.enum(['info', 'warning', 'error']);
export type NotificationType = z.infer<typeof NotificationTypeSchema>;

export const NotificationEntityTypeSchema = z.enum(['time', 'task', 'job']);
export type NotificationEntityType = z.infer<typeof NotificationEntityTypeSchema>;

export const NotificationSchema = z.object({
	id: z.string(),
	type: NotificationTypeSchema.nullable(),
	entityType: NotificationEntityTypeSchema.nullable(),
	entityId: z.string().nullable(),
	infoContent: z.string().nullable(),
	content: z.string().nullable(),
	createdAt: z.date().nullable(),
	updatedAt: z.date().nullable(),
	isRead: z.boolean().nullable(),
	userId: z.string().nullable(),
	createdUser: z.string().nullable(),
	ownerId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
});

export const NotificationWithRelationsSchema = NotificationSchema.extend({
	user: z
		.object({
			id: z.string().cuid().optional(),
		})
		.nullable()
		.optional(),
	deletedAt: z.coerce.date().nullable(),
});

export type Notification = z.infer<typeof NotificationSchema>;
export type NotificationWithRelations = z.infer<typeof NotificationWithRelationsSchema>;
