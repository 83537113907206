import { z } from 'zod';
import { TempNativeLogSchema } from '../model/tempNativeLog';

export const CreateTempNativeLogSchema = TempNativeLogSchema.pick({
	user: true,
	device: true,
	message: true,
	relationId: true,
});

export type CreateTempNativeLog = z.infer<typeof CreateTempNativeLogSchema>;
