import { z } from 'zod';

export const UserSchema = z.object({
	id: z.string(),
	email: z.string().email(),
	password: z.string().nullable(),
	createAt: z.coerce.date().nullable(),
	updateAt: z.coerce.date().nullable(),
	name: z.string().nullable(),
	image: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
});
