import { z } from 'zod';
import { CommentSchema } from '../model/comment';

export const UpdateCommentSchema = CommentSchema.omit({
	id: true,
	employeeId: true,
	taskId: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
});

export type UpdateComment = z.infer<typeof UpdateCommentSchema>;
