import { z } from 'zod';

export const TempNativeLogSchema = z.object({
	id: z.string().cuid(),
	createdAt: z.coerce.date().nullable(),
	user: z.string().nullable(),
	device: z.string().nullable(),
	message: z.string().nullable(),
	relationId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
});
