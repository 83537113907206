import { z } from 'zod';

export const RequestStateSchema = z.enum(['approved', 'pending', 'rejected']);
export type RequestState = z.infer<typeof RequestStateSchema>;
export const RequestSchema = z.object({
	id: z.string().cuid(),
	state: RequestStateSchema.nullable(),
	approvedDate: z.coerce.date().nullable(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	approveId: z.string().nullable(),
	applicantId: z.string().nullable(),
	requestType: z.string().nullable(),
	note: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
});

export const RequestWithRelationsSchema = RequestSchema.extend({
	approver: z
		.object({
			id: z.string(),
			firstName: z.string(),
			surName: z.string(),
		})
		.nullable()
		.optional(),
	applicant: z
		.object({
			id: z.string(),
			firstName: z.string(),
			surName: z.string(),
			image: z.string(),
			userRoles: z.array(
				z.object({
					id: z.string(),
					active: z.boolean(),
					user: z
						.object({
							image: z.string(),
						})
						.nullable()
						.optional(),
				}),
			),
		})
		.nullable()
		.optional(),
	deletedAt: z.coerce.date().nullable(),
});

export type Request = z.infer<typeof RequestSchema>;
export type RequestWithRelations = z.infer<typeof RequestWithRelationsSchema>;
