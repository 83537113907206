import { z } from 'zod';
import { CoordinateSchema } from '../model/coordinate';

export const CreateCoordinateSchema = CoordinateSchema.omit({
	id: true,
	userId: true,
	ownerId: true,
	updatedAt: true,
	createdAt: true,
	deletedAt: true,
});

export type CreateCoordinate = z.infer<typeof CreateCoordinateSchema>;

export const CoordinateExtendSchema = CoordinateSchema.extend({
	user: z.object({
		userRoles: z.array(
			z.object({
				employee: z.object({
					firstName: z.string(),
					surName: z.string(),
					id: z.string(),
				}),
				employeeId: z.string(),
			}),
		),
	}),
	time: z.object({
		end: z.string().nullable(),
		task: z.object({
			id: z.string(),
			name: z.string(),
			job: z.object({
				id: z.string(),
				title: z.string(),
			}),
		}),
	}),
});

export type CoordinateExtend = z.infer<typeof CoordinateExtendSchema>;

export const CoordinatesQueryResultSchema = z.object({
	employeeId: z.string(),
	firstName: z.string(),
	surName: z.string(),
	image: z.string().nullable(),
	jobTitle: z.string().nullable(),
	workSchedules: z.array(
		z.object({
			id: z.string(),
			type: z.string(),
			date: z.string(),
		}),
	),
	positions: z.array(
		z.object({
			id: z.string(),
			latitude: z.number(),
			longitude: z.number(),
			accuracy: z.number(),
			createdAt: z.string(),
			ownerId: z.string(),
			userId: z.string(),
			userImage: z.string().nullable(),
			time: z
				.object({
					id: z.string(),
					start: z.string(),
					end: z.string().nullable(),
					task: z
						.object({
							id: z.string(),
							name: z.string(),
						})
						.nullable(),
					job: z
						.object({
							id: z.string(),
							title: z.string(),
						})
						.nullable(),
				})
				.nullable(),
		}),
	),
});

export type CoordinatesQueryResult = z.infer<typeof CoordinatesQueryResultSchema>;

export const GetCoordinatesParamsQuery = z.object({
	selectedDate: z.coerce.date(),
	taskId: z.string().nullable().optional(),
	employeeIds: z.array(z.string()).optional(),
});

export type GetCoordinatesParams = z.infer<typeof GetCoordinatesParamsQuery>;

export const GetLastCoordinatesParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
	jobIds: z.array(z.string()).nullable().optional(),
	userIds: z.array(z.string()).nullable().optional(),
	taskIds: z.array(z.string()).nullable().optional(),
	employeeIds: z.array(z.string()).nullable().optional(),
});

export type GetLastCoordinatesParams = z.infer<typeof GetLastCoordinatesParamsQuery>;

export const CoordinateFilterValuesSchema = z.array(
	z.object({
		label: z.string(),
		value: z.string(),
	}),
);
export type CoordinateFilterValues = z.infer<typeof CoordinateFilterValuesSchema>;

export const GetCoordinateTasksParamsQuery = z.object({
	jobId: z.string(),
	selectedDate: z.coerce.date(),
});

export type GetCoordinateTasksParams = z.infer<typeof GetCoordinateTasksParamsQuery>;

export const GetCoordinateEmployeesParamsQuery = z.object({
	selectedDate: z.coerce.date(),
});

export type GetCoordinateEmployeesParams = z.infer<typeof GetCoordinateEmployeesParamsQuery>;

export const GetCoordinateJobsParamsQuery = z.object({
	selectedDate: z.coerce.date(),
});

export type GetCoordinateJobsParams = z.infer<typeof GetCoordinateJobsParamsQuery>;
