import { z } from 'zod';
import { UiSettingsSchema } from '../model/uiSettings';

export const UpdateUiSettingsSchema = UiSettingsSchema.extend({
	ownerId: z.string(),
});

export type UpdateUiSettings = z.infer<typeof UpdateUiSettingsSchema>;

export const GetUiSettingsByIdParamsQuery = z.object({
	ownerId: z.string(),
});

export type GetUiSettingsByIdParams = z.infer<typeof GetUiSettingsByIdParamsQuery>;

export const GetUiSettingsParamsQuery = z.object({
	ownerId: z.string(),
	userId: z.string(),
});

export type GetUiSettingsParams = z.infer<typeof GetUiSettingsParamsQuery>;
