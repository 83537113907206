import { z } from 'zod';

export const CoordinateSchema = z.object({
	id: z.string().cuid(),
	userId: z.string().nullable(),
	ownerId: z.string(),
	latitude: z.number().nullable(),
	longitude: z.number().nullable(),
	accuracy: z.number().nullable(),
	speed: z.number().nullable(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date().nullable(),
	timeId: z.string().nullable(),
	workScheduleId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
});

export type Coordinate = z.infer<typeof CoordinateSchema>;
