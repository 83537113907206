import { z } from 'zod';
import { TimeSchema, TimeWithRelationsSchema } from '../model/time';
import {
	createCursorPaginatedResponseSchema,
	CursorPaginatedRequestParamsQuery,
	CursorPaginatedRequestParams,
} from './common';
import { TaskStateSchema } from '../model/task';

export const UpdateTimeSchema = TimeSchema.omit({
	id: true,
	task: true,
	coordinateId: true,
	deletedAt: true,
	end: true,
})
	.extend({
		hourlyRate: z.number().optional().nullable(),
		start: z.coerce.date().optional(),
		employeeId: z.string().nullish(),
		taskId: z
			.string()
			.nullish()
			.refine((data) => Boolean(data), { params: { i18n: 'taskId' } }),
		jobId: z.string().nullish(),
		end: z
			.union([z.coerce.date(), z.string()])
			.nullable()
			.transform((val) => (val === 'now' ? new Date() : val)),
	})
	.refine((data) => (data.start && data.end ? data.start < data.end : true), {
		params: { i18n: 'startDateIsAfterEndDate' },
		path: ['start'],
	});

export type UpdateTime = z.infer<typeof UpdateTimeSchema>;

export const CreateTimeSchema = TimeSchema.omit({
	id: true,
	task: true,
	start: true,
	coordinateId: true,
	deletedAt: true,
})
	.extend({
		hourlyRate: z.number().optional().nullable(),
		jobId: z.string().nullish(),
		start: z.coerce.date().optional(),
	})
	.refine((data) => (data.start && data.end ? data.start < data.end : true), {
		params: { i18n: 'startDateIsAfterEndDate' },
		path: ['start'],
	});

export type CreateTime = z.infer<typeof CreateTimeSchema>;

export const FindTimeQuerySchema = z.object({
	where: z
		.object({
			start: z
				.object({
					gte: z.coerce.date().optional(),
					lte: z.coerce.date().optional(),
				})
				.optional(),
			OR: z
				.array(
					z.object({
						end: z
							.object({
								gte: z.coerce.date().optional(),
								lte: z.coerce.date().optional(),
							})
							.optional()
							.nullable(),
					}),
				)
				.optional(),
			AND: z
				.array(
					z.object({
						end: z
							.object({
								gte: z.coerce.date().optional(),
								lte: z.coerce.date().optional(),
							})
							.optional()
							.nullable(),
						start: z
							.object({
								gte: z.coerce.date().optional(),
								lte: z.coerce.date().optional(),
							})
							.optional(),
					}),
				)
				.optional(),
			employeeId: z.string().cuid().optional().nullish(),
			taskId: z.string().cuid().optional(),
			task: z
				.object({
					job: z
						.object({
							ownerId: z.string().cuid().optional(),
						})
						.optional(),
				})
				.optional(),
		})
		.optional(),
	include: z
		.object({
			task: z.object({
				include: z.object({
					job: z.boolean().optional(),
				}),
			}),
			employee: z.boolean().optional(),
		})
		.optional(),
	orderBy: z
		.object({
			start: z.enum(['asc', 'desc']).optional(),
			end: z.enum(['asc', 'desc']).optional(),
		})
		.optional(),
	take: z.number().int().optional(),
});

export type FindTimeQuery = z.infer<typeof FindTimeQuerySchema>;

export const GetTimesParamsQuery = CursorPaginatedRequestParamsQuery.extend({
	q: FindTimeQuerySchema.optional(),
});

export type GetTimesParams = z.infer<typeof GetTimesParamsQuery>;

export const ExtendedFieldArrayTimeSchema = TimeSchema.omit({
	id: true,
	task: true,
})
	.extend({
		newId: z.string().optional().nullable(),
		hourlyRate: z.number().optional().nullable(),
		start: z.coerce.date().optional(),
		taskId: z
			.string()
			.nullable()
			.refine((data) => Boolean(data), { params: { i18n: 'taskId' } }),
		end: z
			.union([z.coerce.date(), z.string()])
			.nullable()
			.transform((val) => (val === 'now' ? new Date() : val)),
		jobId: z.string().nullish(),
	})
	.refine((data) => (data.start && data.end ? data.start < data.end : true), {
		params: { i18n: 'startDateIsAfterEndDate' },
		path: ['start'],
	});

export const FieldArrayTimeSchema = z.object({
	times: z.array(ExtendedFieldArrayTimeSchema),
});

export const FieldArrayTimeParamsSchema = z.object({
	times: z.array(ExtendedFieldArrayTimeSchema),
	initialTimes: z.array(ExtendedFieldArrayTimeSchema),
});

export type FieldArrayTimeParams = z.infer<typeof FieldArrayTimeParamsSchema>;

export type FieldArrayTime = z.infer<typeof FieldArrayTimeSchema>;

export const GetTimeResponseSchema = TimeSchema.extend({
	hourlyRate: z.number().optional().nullable(),
});

export type GetTimeResponse = z.infer<typeof GetTimeResponseSchema>;

export const GetTimesPaginatedResponseSchema = createCursorPaginatedResponseSchema(TimeWithRelationsSchema);

export type GetTimesPaginatedResponse = z.infer<typeof GetTimesPaginatedResponseSchema>;

export const GroupedTimeSchema = z.object({
	id: z.string(),
	firstName: z.string(),
	surName: z.string(),
	start: z.date(),
	employeeId: z.string(),
	jobTitle: z.string(),
	profilePicture: z.string().optional(),
	updatedAt: z.date().optional(),
	totalTime: z.string(),
	totalPrice: z.string(),
});

export type GroupedTime = z.infer<typeof GroupedTimeSchema>;

export const GroupedTimesSchema = z.object({
	id: z.string(),
	firstName: z.string(),
	surName: z.string(),
	profilePicture: z.string().optional(),
	updatedAt: z.date().optional(),
	totalTime: z.string(),
	totalPrice: z.string(),
	jobTitle: z.string(),
	children: z.array(
		z.object({
			id: z.string(),
			firstName: z.string(),
			surName: z.string(),
			start: z.date(),
			employeeId: z.string(),
			jobTitle: z.string(),
			profilePicture: z.string().optional(),
			updatedAt: z.date().optional(),
			totalTime: z.string(),
			totalPrice: z.string(),
		}),
	),
});

export type GroupedTimes = z.infer<typeof GroupedTimesSchema>;

export const GetDayModalTimesParamsQuery = z.object({
	start: z
		.object({
			gte: z.coerce.date(),
			lte: z.coerce.date(),
		})
		.optional(),
});

export type GetDayModalTimesParams = z.infer<typeof GetDayModalTimesParamsQuery>;

export const TimeTaskOverviewSchema = z.object({
	sumWithoutEnd: z.number(),
	sumCurrent: z.number(),
	name: z.string().nullable().optional(),
	jobTitle: z.string().nullable().optional(),
	employeeId: z.string().nullable().optional(),
	state: TaskStateSchema.nullable().optional(),
	hasEntryWithoutEnd: z.boolean(),
	entryWithoutEndStart: z.coerce.date().optional().nullable(),
	entriesLength: z.number(),
	id: z.string(),
});

export type TimeTaskOverview = z.infer<typeof TimeTaskOverviewSchema>;

export const TimeOverviewSchema = z.object({
	date: z.string(),
	pauseMs: z.number(),
	lastSortedEntryEnd: z.coerce.date().optional().nullable(),
	sumWithoutEnd: z.number(),
	sumCurrent: z.number(),
	tasks: z.array(TimeTaskOverviewSchema),
});

export type TimeOverview = z.infer<typeof TimeOverviewSchema>;

export type GetTimesOverviewParams = CursorPaginatedRequestParams;

export const TimeOverviewPaginatedResponseSchema = createCursorPaginatedResponseSchema(TimeOverviewSchema);

export type TimeOverviewPaginatedResponse = z.infer<typeof TimeOverviewPaginatedResponseSchema>;

export const EntryWithoutEndSchema = z.object({
	id: z.string(),
	start: z.coerce.date(),
	taskId: z.string().optional().nullable(),
});

export type EntryWithoutEnd = z.infer<typeof EntryWithoutEndSchema>;

export const EntryWithoutEndResponseSchema = z.object({
	entry: EntryWithoutEndSchema.nullable(),
});

export type EntryWithoutEndResponse = z.infer<typeof EntryWithoutEndResponseSchema>;
