import { z } from 'zod';
import { EmployeeSchema } from '../model/employee';
import { WorkScheduleSchema } from '../model/workSchedule';
import { TimeSchema } from '../model/time';
import { UserRoleSchema, UserRoleWithRelationsSchema } from '../model/userRole';
import { CreateAddressSchema, ImportAddressSchema } from './addresses';
import { InvitationSchema } from '../model/invitation';

export const UpdateEmployeeSchema = EmployeeSchema.omit({
	id: true,
	times: true,
	workSchedule: true,
	userRoles: true,
	invitations: true,
	deletedAt: true,
}).extend({
	// toto prepisuje ten debilni decimal typ ktery nejde vypnout
	hourlyRate: z.number().nullish(),
	insuranceNumber: z.number().nullable().optional(),
	role: z.enum(['user', 'admin', 'superAdmin']).nullable().optional(),
	active: z.boolean().nullable().optional(),
});

export type UpdateEmployee = z.infer<typeof UpdateEmployeeSchema>;

export const FindEmployeeQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		email: z.string().email().optional(),
		ownerId: z.string().cuid(),
		NOT: z
			.object({
				userRoles: z
					.object({
						some: z.object({
							employeeId: z.object({
								not: z.string().cuid().nullable(),
							}),
						}),
					})
					.optional(),
			})
			.optional(),
	}),
	include: z
		.object({
			times: z
				.object({
					where: z.object({
						start: z
							.object({
								gte: z.coerce.date().optional(),
								lte: z.coerce.date().optional(),
							})
							.optional(),
						OR: z
							.array(
								z.object({
									end: z
										.object({
											gte: z.coerce.date().optional(),
											lte: z.coerce.date().optional(),
										})
										.optional()
										.nullable(),
								}),
							)
							.optional(),
					}),
					include: z
						.object({
							task: z.object({
								include: z.object({
									job: z.boolean(),
								}),
							}),
						})
						.optional(),
				})
				.optional(),
			workSchedule: z
				.object({
					where: z
						.object({
							date: z
								.object({
									gte: z.string().optional(),
									lte: z.string().optional(),
								})
								.optional(),
						})
						.optional(),
					include: z.object({
						request: z.boolean(),
						qrcode: z.boolean(),
					}),
				})
				.optional(),
			userRoles: z.object({
				include: z.object({
					user: z.boolean(),
				}),
			}),
		})
		.optional(),
});

export type FindEmployeeQuery = z.infer<typeof FindEmployeeQuerySchema>;

export const GetEmployeesParamsQuery = z.object({
	withUsers: z.boolean().optional().default(false),
});

export type GetEmployeesParams = z.infer<typeof GetEmployeesParamsQuery>;

export const CreateEmployeeSchema = EmployeeSchema.omit({
	id: true,
	times: true,
	workSchedule: true,
	userRoles: true,
	deletedAt: true,
	invitations: true,
}).extend({
	// toto prepisuje ten debilni decimal typ ktery nejde vypnout
	insuranceNumber: z.number().nullable().optional(),
	hourlyRate: z.number().nullish(),
	role: z.enum(['user', 'admin', 'superAdmin']),
	addressesToCreate: z.array(CreateAddressSchema).optional(),
});

export type CreateEmployee = z.infer<typeof CreateEmployeeSchema>;

export const InviteEmployeeSchema = EmployeeSchema.pick({
	id: true,
	email: true,
}).extend({
	role: z.enum(['user', 'admin', 'superAdmin']),
});

export type InviteEmployee = z.infer<typeof InviteEmployeeSchema>;

export const ReviewTableWorkScheduleSchema = z.object({
	date: z.string(),
	workScheduleType: z.string(),
	createdAt: z.coerce.date(),
	id: z.string(),
	qrcode: z
		.object({
			location: z.string(),
		})
		.optional(),
	request: z
		.object({
			note: z.string(),
			state: z.string(),
		})
		.optional(),
	attendanceDuration: z.number().optional(),
	attendanceType: z.string().optional(),
});

export type ReviewTableWorkSchedule = z.infer<typeof ReviewTableWorkScheduleSchema>;

export const ReviewTableLogTimeSchema = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
	dayColor: z.string(),
	totalTime: z.string().optional(),
	isAdmin: z.boolean(),
	isSuperAdmin: z.boolean(),
	dayInMonth: z.string(),
	day: z.coerce.date(),
	startWorkSchedule: ReviewTableWorkScheduleSchema.optional(),
	endWorkSchedule: ReviewTableWorkScheduleSchema.optional(),
	jobs: z.array(
		z.object({
			start: z.date(),
			end: z.date(),
			hourlyRate: z.number(),
			task: z.object({
				job: z.object({
					title: z.string(),
					beginDate: z.date(),
					endDate: z.date(),
				}),
			}),
		}),
	),
});

export const ReviewTableDataEmployeeSchema = z.object({
	id: z.string(),
	ownerId: z.string(),
	firstName: z.string(),
	surName: z.string(),
	jobTitle: z.string().nullable(),
	image: z.string().optional(),
	role: z.enum(['user', 'admin', 'superAdmin']),
	typeOfContract: z.string().nullable(),
});

export type ReviewTableDataEmployee = z.infer<typeof ReviewTableDataEmployeeSchema>;

export const ReviewTableDataSchema = z.object({
	employee: ReviewTableDataEmployeeSchema,
	sum: z.object({
		total: z.string(),
		overtime: z.string(),
		superAdminTotal: z.string().optional(),
	}),
	'1': ReviewTableLogTimeSchema.optional(),
	'2': ReviewTableLogTimeSchema.optional(),
	'3': ReviewTableLogTimeSchema.optional(),
	'4': ReviewTableLogTimeSchema.optional(),
	'5': ReviewTableLogTimeSchema.optional(),
	'6': ReviewTableLogTimeSchema.optional(),
	'7': ReviewTableLogTimeSchema.optional(),
	'8': ReviewTableLogTimeSchema.optional(),
	'9': ReviewTableLogTimeSchema.optional(),
	'10': ReviewTableLogTimeSchema.optional(),
	'11': ReviewTableLogTimeSchema.optional(),
	'12': ReviewTableLogTimeSchema.optional(),
	'13': ReviewTableLogTimeSchema.optional(),
	'14': ReviewTableLogTimeSchema.optional(),
	'15': ReviewTableLogTimeSchema.optional(),
	'16': ReviewTableLogTimeSchema.optional(),
	'17': ReviewTableLogTimeSchema.optional(),
	'18': ReviewTableLogTimeSchema.optional(),
	'19': ReviewTableLogTimeSchema.optional(),
	'20': ReviewTableLogTimeSchema.optional(),
	'21': ReviewTableLogTimeSchema.optional(),
	'22': ReviewTableLogTimeSchema.optional(),
	'23': ReviewTableLogTimeSchema.optional(),
	'24': ReviewTableLogTimeSchema.optional(),
	'25': ReviewTableLogTimeSchema.optional(),
	'26': ReviewTableLogTimeSchema.optional(),
	'27': ReviewTableLogTimeSchema.optional(),
	'28': ReviewTableLogTimeSchema.optional(),
	'29': ReviewTableLogTimeSchema.optional(),
	'30': ReviewTableLogTimeSchema.optional(),
	'31': ReviewTableLogTimeSchema.optional(),
});

export type ReviewTableData = z.infer<typeof ReviewTableDataSchema>;

export const ReviewTableResponseSchema = z.object({
	data: z.array(ReviewTableDataSchema),
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
	employeeCount: z.number(),
});

export type ReviewTableResponse = z.infer<typeof ReviewTableResponseSchema>;

export const EmployeeWithWorkScheduleSchema = EmployeeSchema.extend({
	workSchedule: z.array(WorkScheduleSchema).nullish(),
	times: z.array(TimeSchema).nullish(),
	userRoles: z.array(UserRoleSchema).nullish(),
	invitations: z.array(InvitationSchema).nullish(),
});

export type EmployeeWithWorkSchedule = z.infer<typeof EmployeeWithWorkScheduleSchema>;

export const EmployeeWithWorkScheduleExtendedSchema = EmployeeWithWorkScheduleSchema.extend({
	canBeActive: z.boolean().nullable(),
	isSubscriptionActiveButCancelled: z.boolean().nullable(),
});

export type EmployeeWithWorkScheduleExtended = z.infer<typeof EmployeeWithWorkScheduleExtendedSchema>;

export const GetDataForReviewTableParamsQuery = z.object({
	ownerId: z.string().optional(),
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
	globalFilter: z.string().nullable().optional(),
	filterActiveOnly: z.boolean().optional(),
	pagination: z
		.object({
			page: z.number().optional(),
			pageSize: z.number().optional(),
		})
		.optional(),
});

export type GetDataForReviewTableParams = z.infer<typeof GetDataForReviewTableParamsQuery>;

export const BestAndWorstEmployeSchema = EmployeeSchema.extend({
	workSchedule: z.array(WorkScheduleSchema).nullish(),
	times: z.array(TimeSchema).nullish(),
	userRoles: z.array(UserRoleWithRelationsSchema).nullish(),
	totalTime: z.number().nullable(),
	highestTotalTime: z.number().nullable(),
	timeBarFill: z.number().nullable(),
});

export type BestAndWorstEmploye = z.infer<typeof BestAndWorstEmployeSchema>;

export const BestAndWorstEmployeesSchema = z.object({
	bestEmployees: z.array(BestAndWorstEmployeSchema),
	worstEmployees: z.array(BestAndWorstEmployeSchema),
});

export type BestAndWorstEmployees = z.infer<typeof BestAndWorstEmployeesSchema>;

export const EmployeeImportResponseSchema = EmployeeSchema.omit({
	image: true,
	placeOfBirth: true,
	startDate: true,
	endDate: true,
	endContractDate: true,
	hourlyRate: true,
	times: true,
	workSchedule: true,
	invitations: true,
	userRoles: true,
	deletedAt: true,
}).extend({
	addresses: z.array(ImportAddressSchema).nullish(),
});

export type EmployeeImportResponse = z.infer<typeof EmployeeImportResponseSchema>;

export const EmployeeImportRequestSchema = z.object({
	fileData: z.array(z.array(z.string())),
});

export type EmployeeImportRequest = z.infer<typeof EmployeeImportRequestSchema>;

export const ActiveEmployeesSchema = z.object({
	activeEmployeesCount: z.number(),
	employeesCount: z.number(),
	lastArrivalEmployees: z.array(z.object({ firstName: z.string(), surName: z.string(), startTime: z.coerce.date() })),
	activeEmp: z.array(EmployeeWithWorkScheduleSchema),
});

export type ActiveEmployees = z.infer<typeof ActiveEmployeesSchema>;

export const GetActiveEmployeesParamsQuery = z.object({
	time: z.object({ gte: z.coerce.date(), lte: z.coerce.date() }),
	workSchedule: z.object({ gte: z.string().date(), lte: z.string().date() }),
	job: z.boolean(),
});

export type GetActiveEmployeesParams = z.infer<typeof GetActiveEmployeesParamsQuery>;

export const DailyEarningsWithTotalEarningsSchema = z.object({
	dailyEarnings: z.array(z.object({ name: z.string(), sum: z.number() })),
	totalEarnings: z.number(),
});

export type DailyEarningsWithTotalEarnings = z.infer<typeof DailyEarningsWithTotalEarningsSchema>;

export const GetMonthlySpendsParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
});

export type GetMonthlySpendsParams = z.infer<typeof GetMonthlySpendsParamsQuery>;

export const GetBestAndWorstEmployeesParamsQuery = z.object({
	ownerId: z.string(),
});

export type GetBestAndWorstEmployeesParams = z.infer<typeof GetBestAndWorstEmployeesParamsQuery>;

export const DailyHoursWithTotalHoursSchema = z.object({
	dailyHours: z.array(z.object({ name: z.string(), sum: z.number() })),
	totalHours: z.number(),
});

export type DailyHoursWithTotalHours = z.infer<typeof DailyHoursWithTotalHoursSchema>;

export const GetMonthlyHoursOfAllEmployeesParamsQuery = z.object({
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
});

export type GetMonthlyHoursOfAllEmployeesParams = z.infer<typeof GetMonthlyHoursOfAllEmployeesParamsQuery>;

export const GetOwnerEmployeesInfoSchema = z.object({
	image: z.string().nullable(),
	firstName: z.string(),
	id: z.string(),
	surName: z.string(),
	email: z.string(),
	userId: z.string(),
	jobTitle: z.string().nullable(),
});

export type GetOwnerEmployeesInfo = z.infer<typeof GetOwnerEmployeesInfoSchema>;

export const GetEmployeeTimeSheetParamsQuery = z.object({
	ownerId: z.string(),
	startDate: z.coerce.date(),
	endDate: z.coerce.date(),
});

export type GetEmployeeTimeSheetParams = z.infer<typeof GetEmployeeTimeSheetParamsQuery>;

const EmployeeTimeSheetWorkSchedule = z
	.object({
		date: z.string().datetime(),
		workScheduleType: z.string(),
		createdAt: z.string().datetime(),
		id: z.string(),
		attendanceDuration: z.number().nullable(),
		attendanceType: z.string().nullable(),
	})
	.nullable();

export const EmployeeTimeSheetResponseSchema = z.object({
	employee: z.object({
		id: z.string(),
		firstName: z.string(),
		surName: z.string(),
		jobTitle: z.string(),
		image: z.string().nullable(),
		role: z.string(),
		typeOfContract: z.string(),
		ownerId: z.string(),
		address: z.string().nullable(),
	}),
	days: z.array(
		z.object({
			day: z.string().date(),
			startWorkSchedule: EmployeeTimeSheetWorkSchedule,
			endWorkSchedule: EmployeeTimeSheetWorkSchedule,
			absenceWorkSchedule: z.array(EmployeeTimeSheetWorkSchedule),
			jobs: z.array(
				z.object({
					start: z.string().datetime(),
					end: z.string().datetime(),
					hourlyRate: z.number().nullable(),
					title: z.string(),
				}),
			),
			overtime: z.number(),
			totalTimeFormatted: z.string().nullable(),
			totalTime: z.number(),
			dailyTotalPrice: z.number(),
		}),
	),
});

export type EmployeeTimeSheetResponse = z.infer<typeof EmployeeTimeSheetResponseSchema>;
