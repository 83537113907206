import { z } from 'zod';

export const PersonTypeSchema = z.enum(['individual', 'legalEntity', 'selfEmployed']);
export type PersonType = z.infer<typeof PersonTypeSchema>;

export const CustomerSchema = z.object({
	id: z.string().cuid(),
	personType: PersonTypeSchema.nullable(),
	companyName: z.string().nullable(),
	identificationNumber: z.string().nullable(),
	taxIdentificationNumber: z.string().nullable(),
	email: z.string().email().nullable(),
	phoneNumber: z.string().nullable(),
	firstName: z.string().nullable(),
	surName: z.string().nullable(),
	logo: z.string().nullable(),
	notes: z.string().nullable(),
	ownerId: z.string().nullable(),
	createdAt: z.coerce.date(),
	updatedAt: z.coerce.date().nullable(),
	deletedAt: z.coerce.date().nullable(),
});

export type Customer = z.infer<typeof CustomerSchema>;
