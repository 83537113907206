import { z } from 'zod';

export const WorkScheduleSchema = z.object({
	id: z.string().cuid(),
	createdAt: z.coerce.date().nullable(),
	updatedAt: z.coerce.date().nullable(),
	employeeId: z.string().nullable(),
	qrcodeId: z.string().nullable(),
	requestId: z.string().nullable(),
	date: z.string().nullable(),
	workScheduleType: z.string().nullable(),
	attendanceDuration: z.number().nullable(),
	attendanceType: z.string().nullable(),
	coordinateId: z.string().nullable(),
	deletedAt: z.coerce.date().nullable(),
});

export type WorkSchedule = z.infer<typeof WorkScheduleSchema>;
