import { z } from 'zod';
import { EmployeeSchema } from './employee';
import { UserSchema } from './user';
import { UiSettingsSchema } from './uiSettings';

export const RoleSchema = z.enum(['admin', 'user', 'superAdmin']);
export type Role = z.infer<typeof RoleSchema>;

export const UserRoleSchema = z.object({
	role: RoleSchema.nullable(),
	id: z.string().cuid(),
	active: z.boolean(),
	userId: z.string().nullable(),
	ownerId: z.string().nullable(),
	employeeId: z.string().nullable(),
	employee: EmployeeSchema.nullish(),
	deletedAt: z.coerce.date().nullable(),
	uiSettings: UiSettingsSchema.nullable(),
});

export const UserRoleWithRelationsSchema = UserRoleSchema.extend({
	user: UserSchema.nullish(),
});

export type UserRole = z.infer<typeof UserRoleSchema>;
export type UserRoleWithRelations = z.infer<typeof UserRoleWithRelationsSchema>;
export type UiSettings = z.infer<typeof UiSettingsSchema>;
