import { z } from 'zod';

import { ContactPersonSchema } from '../model/contactPerson';

export const UpdateContactPersonSchema = ContactPersonSchema.omit({
	id: true,
	customerId: true,
	deletedAt: true,
});

export type UpdateContactPerson = z.infer<typeof UpdateContactPersonSchema>;

export const CreateContactPersonSchema = ContactPersonSchema.omit({
	id: true,
	deletedAt: true,
});

export type CreateContactPerson = z.infer<typeof CreateContactPersonSchema>;

export const FindContactPersonQuerySchema = z.object({
	where: z.object({
		id: z.string().cuid().optional(),
		customerId: z.string().cuid().optional(),
	}),
});

export type FindContactPersonQuery = z.infer<typeof FindContactPersonQuerySchema>;

export const GetContactPersonsParamsQuery = z.object({
	q: FindContactPersonQuerySchema.optional(),
	ownerId: z.string().cuid(),
});
export type GetContactPersonsParams = z.infer<typeof GetContactPersonsParamsQuery>;
